import { Avatar, Box, Button, CircularProgress, Fade, TextField, Typography, Tabs, Tab } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TeamProgramsTable } from './TeamProgramsTable';
import { usePostBodyWithJwtMutation } from 'src/redux/api/cabinet';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import { useSearchParams } from 'react-router-dom';

export const TeamProgramsPage = () => {
    const [partners, setPartners] = useState<any[]>([]);
    const { data } = useSelector((state: AppState) => state.app);
    const [checkUser, setCheckUser] = useState<number>(data?.id || 0);
    const [activeUser, setActiveUser] = useState<any>(null);
    const [refererId, setRefererId] = useState<number>(0);
    const [allState, setAllState] = useState(1);
    const [searchState, setSearchState] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParam = searchParams.get('search');
    const allParam = searchParams.get('all');

    const [getPartnersData, { isLoading }] = usePostBodyWithJwtMutation();

    const updPersonInfoId = (id: number, refererId: number) => {
        setCheckUser(id);
    };

    const getPartnersRequest = async (anotherParams: any = {}) => {
        const jwt = localStorage.getItem('jwt');
        const route = 'team-program';
        const body = { user_id: checkUser, ...anotherParams };

        try {
            const payload = await getPartnersData({ body, jwt, route }).unwrap();
            setPartners(payload?.data.teams || []);
            setRefererId(payload?.data.info.referer_id || 0);
            setActiveUser(payload?.data.info || null);
        } catch (error: any) {
            alert(error?.data?.message || "Ошибка сервера");
            setActiveUser(null);
            setPartners([]);
        }
    };

    const [searchInput, setSearchInput] = useState(searchParam || '');
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    // Функция для обновления поиска с задержкой
    const handleSearchChange = useCallback((value: string) => {
        setSearchInput(value);

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            setSearchState(value);
            const newParams = new URLSearchParams(searchParams);
            newParams.set('search', value);
            setSearchParams(newParams);
        }, 200);

        setTypingTimeout(newTimeout);
    }, [searchParams, typingTimeout, setSearchParams]);

    // Очистка таймера при размонтировании
    useEffect(() => {
        return () => {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
        };
    }, [typingTimeout]);

    // Обработка изменения таба
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setAllState(newValue);
        const newParams = new URLSearchParams(searchParams);
        newParams.set('all', newValue.toString());
        setSearchParams(newParams);

    };

    const handleSearchSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        getPartnersRequest({
            all: allState,
            search: searchParam,
        });
    };

    // Синхронизация таба с параметром `all` из URL
    useEffect(() => {
        const allParamValue = allParam ? parseInt(allParam, 10) : 1;
        setAllState(allParamValue);
    }, [allParam]);

    useEffect(() => {
        if (data?.id) {
            updPersonInfoId(data.id, 0);
        }
    }, [data?.id]);

    useEffect(() => {
        const newParams = new URLSearchParams(searchParams);

        if (!searchParam) {
            newParams.set('search', '');
        }

        if (!allParam) {
            newParams.set('all', '1');
        }

        setSearchParams(newParams);

        getPartnersRequest({
            all: allParam || '1',
            search: searchParam || '',
        });

        setSearchState(searchParam || '');
    }, [checkUser]);

    useEffect(() => {
        getPartnersRequest({
            all: allParam || '1',
            search: searchParam || '',
        });
    }, [allParam]);

    return (
        <>
            <Typography variant='h4'>Мои партнёры</Typography>

            {/* Блок кнопок навигации */}
            <Fade
                in={!isLoading && data?.id !== activeUser?.id}
                timeout={300}
                mountOnEnter
                unmountOnExit
            >
                <Box display='flex' alignItems='center' justifyContent='space-between' mt={2}>
                    <Box>
                        <Button
                            size="large"
                            sx={{ mr: 1 }}
                            color="primary"
                            variant="contained"
                            onClick={() => updPersonInfoId(data?.id || 0, 0)}
                        >
                            В начало
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            color="success"
                            onClick={() => setCheckUser(refererId)}
                        >
                            Назад
                        </Button>
                    </Box>
                </Box>
            </Fade>

            {/* Информация о пользователе */}
            {activeUser && (
                <Fade in={!isLoading} timeout={300}>
                    <div>
                        <Box display='flex' alignItems="center" mt={2}>
                            <Avatar
                                sx={{ mr: 1 }}
                                src={activeUser.avatar || ''}
                                alt={activeUser.login}
                            />
                            <Typography variant='h5'>{activeUser.login}</Typography>
                        </Box>

                        {/* Табы для управления параметром `all` */}
                        <Box mt={3}>
                            <Tabs value={allState} onChange={handleTabChange} aria-label="all tabs">
                                <Tab label="Все партнёры" value={1} />
                                <Tab label="Активные" value={2} />
                                <Tab label="Неактивные" value={3} />
                            </Tabs>
                        </Box>

                        {/* Поле поиска и кнопка */}
                        <Box mt={3} display="flex" gap={2} alignItems="center">
                            <form onSubmit={handleSearchSubmit} style={{ width: '100%' }}>
                                <Box display='inline-flex' width='100%' alignItems='center'>
                                    <TextField
                                        label="Поиск по логину"
                                        variant="outlined"
                                        value={searchInput}
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        fullWidth
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ ml: 1 }} // Для выравнивания с полем ввода
                                    >
                                        Искать
                                    </Button>
                                </Box>

                            </form>
                        </Box>
                    </div>
                </Fade>
            )}

            {/* Содержимое страницы */}
            <Box pb={4}>
                {isLoading ? (
                    <Fade in={isLoading} timeout={300}>
                        <Box display='flex' justifyContent='center' alignItems='center' minHeight={500}>
                            <CircularProgress />
                        </Box>
                    </Fade>
                ) : (
                    <>
                        {partners.length > 0 ? (
                            <Fade in={true} timeout={300}>
                                <Box>
                                    <TeamProgramsTable partners={partners} updPersonInfoId={updPersonInfoId} />
                                </Box>
                            </Fade>
                        ) : (
                            <Box display='flex' justifyContent='center' alignItems='center' minHeight={300}>
                                <Typography variant='h6'>Партнёров нет</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </>
    );
};