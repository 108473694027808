const { REACT_APP_NAME } = process.env;
import { Box, Card, CardContent, CardHeader, Grid, LinearProgress, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetTeamsMutation } from "src/redux/api/cabinet";
import { AppState } from "src/store/Store";
import { Alerting } from "src/views/components_cabinet/Alerting"
import { PartnersContent } from "./PartnersContent";
import PageContainer from "src/components/container/PageContainer";



const skeleton = () => (
    <Box>
        <LinearProgress sx={{ mb: 3 }} />
        <Card >
            <CardHeader
                sx={{ mb: 0, py: 0 }}
                title={<Skeleton variant="rounded" width={210} height={30} />}
            />
            <CardContent>
                <Grid container spacing={2} justifyContent='center' display='flex'>
                    <Grid item xs={12} display='flex' alignItems='center' flexDirection='column'>
                        <Typography variant="h5" mb={1}><Skeleton variant="rounded" width={160} height={30} /></Typography>
                        <Typography sx={{ color: '#9c9c9c' }}><Skeleton variant="rounded" width={160} height={20} /></Typography>
                    </Grid>
                    <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                        <Typography variant="h5" mb={1}><Skeleton variant="rounded" width={160} height={30} /></Typography>
                        <Typography sx={{ color: '#9c9c9c' }}><Skeleton variant="rounded" width={160} height={20} /></Typography>
                    </Grid>
                    <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                        <Typography variant="h5" mb={1}><Skeleton variant="rounded" width={160} height={30} /></Typography>
                        <Typography sx={{ color: '#9c9c9c' }}><Skeleton variant="rounded" width={160} height={20} /></Typography>
                    </Grid>
                    <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                        <Typography variant="h5" mb={1}><Skeleton variant="rounded" width={160} height={30} /></Typography>
                        <Typography sx={{ color: '#9c9c9c' }}><Skeleton variant="rounded" width={160} height={20} /></Typography>
                    </Grid>
                    <Grid item xs={6} md={3} display='flex' alignItems='center' flexDirection='column'>
                        <Typography variant="h5" mb={1}><Skeleton variant="rounded" width={160} height={30} /></Typography>
                        <Typography sx={{ color: '#9c9c9c' }}><Skeleton variant="rounded" width={160} height={20} /></Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Box mt={5}>
            <Skeleton variant="rounded" width={"100%"} height={480} />
        </Box>
    </Box>
)


export const PartnersOfficePage = () => {
    const { data } = useSelector((state: AppState) => state.app);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const [answerData, setAnswerData] = useState<any>(undefined);
    const [personInfoId, setPersonInfoId] = useState<number>(0);
    const updPersonInfoId = (id: number) => setPersonInfoId(id);

    const [getPartnersData, { isLoading, isSuccess }] = useGetTeamsMutation();
    const getPartnersRequest = async (uid: number) => {
        const jwt = localStorage.getItem('jwt');
        await getPartnersData({ body: { user_id: uid }, jwt })
            .unwrap()
            .then((payload: any) => {
                // console.log('getPartnersData');
                // console.log(payload);
                setAnswerData(payload.data);
                // dispatch(updProfileData(payload.data));
                // notify('Данные успешно изменены');
            })
            .catch((error: any) => {
                // console.log('err getPartnersData');
                console.log(error);
                // notifyErr(error.data.message);
                // setAlert(true);
                // setAlertMsg(error.data.message);
                // setAlertStatus('success');
            })
    }

    useEffect(() => {
        data && setPersonInfoId(data.id)
    }, []);

    useEffect(() => {
        personInfoId > 0 && getPartnersRequest(personInfoId)
    }, [personInfoId]);

    return (
        <>
            <PageContainer title={`${REACT_APP_NAME} | Партнёры`} description="Ваши партнёры">
                <Box sx={{ my: 5 }}>
                    {isLoading && skeleton()}
                    {!isLoading && answerData && <PartnersContent updPersonInfoId={updPersonInfoId} data={answerData} />}
                    {getAlert && <Alerting get={getAlert} />}
                </Box>
            </PageContainer>
        </>
    )
}
