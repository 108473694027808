import { Box, Button, colors, Container, Fade, IconButton, Theme, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom'


import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import s from "./levels.module.scss";


interface Ilevels {
    levels: any[]
    nowProgram: any
}

export const Levels: FC<Ilevels> = ({ levels, nowProgram }) => {
    const xl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
    const [initSlide, setInitSlide] = useState<number | undefined>(undefined);
    const [searchParams] = useSearchParams();
    const programParam = searchParams.get('p');
    const levelParam = searchParams.get('l');
    const [nowTitleLevel, setnowTitleLevel] = useState('');
    const [countSliderToPage, setCountSliderToPage] = useState(4);
    const swiperRef = useRef<any>();

    const swiper = useSwiper();
    // const swiperSlide = useSwiperSlide();

    const nowLevel = (levels: any, levelParam: any) => {
        levels.forEach((e: any) => {
            if (e.summ === Number(levelParam)) setnowTitleLevel(e.title.replace(/[^0-9]/g, ''));

            return;
        });
    }

    useEffect(() => {
        if (levels) {
            setCountSliderToPage(levels.length < 4 ? levels.length : 4)
            levels && levels.map((e, i) => {
                if (Number(levelParam) === Number(e.summ)) {
                    setInitSlide(i > 0 ? (i) : i);
                }
            })
        }

        nowLevel(levels, levelParam)

    }, [levels]);

    return (
        <>
            {/* <Typography variant="h6" textAlign='center' mb={2}>Уровни</Typography> */}
            <Box display='flex' flex='wrap' justifyContent='center' alignItems='center'>
                <Container sx={{ px: 0, maxWidth: { xs: 'md', md: '100%' }, position: "relative" }}>
                    <Box sx={{ position: { xs: 'relative', lg: "absolute" }, left: 0, top: 0, maxWidth: { xs: "100%", md: "340px" } }}>

                        {/* ПОДПИСАНИЕ ТЕКУЩЕГО УРОВНЯ И ТАРИФА */}
                        <Box position="relative" sx={{ display: { xs: "none", md: "block" } }} display="inline">
                            <Fade in={!!nowProgram}>
                                <div>
                                    <Typography variant="h6" sx={{ color: "#0721e9", textDecoration: "underline", fontWeight: 600 }}>
                                        Тариф: {nowProgram ? nowProgram.name : "Неизвестно"}
                                    </Typography>
                                    <Typography variant="h5" sx={{ color: "#0721e9", fontWeight: 500 }}>
                                        Уровень {nowTitleLevel} - {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(levelParam))}
                                    </Typography>
                                </div>
                            </Fade>
                        </Box>
                        {initSlide !== undefined &&
                            <Swiper
                                // slidesPerView={6}
                                centeredSlides={false}
                                // slidesPerGroupSkip={1}
                                initialSlide={initSlide == 0 ? initSlide : initSlide - 1}
                                spaceBetween={0}
                                grabCursor={true}
                                cssMode={true}
                                // keyboard={{
                                //     enabled: true,
                                // }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 3,
                                        spaceBetween: 5
                                    },
                                    // 768: {
                                    //     slidesPerView: 3.5,
                                    //     spaceBetween: 5
                                    // },
                                    900: {
                                        slidesPerView: countSliderToPage,
                                        spaceBetween: 0
                                    },

                                }}
                                scrollbar={true}
                                navigation={true}
                                // pagination={{
                                //     clickable: true,
                                // }}
                                onBeforeInit={(swiper: any) => {
                                    swiperRef.current = swiper;
                                }}
                                modules={[Keyboard, Navigation]}
                                style={{ position: "relative" }}
                                className={`mySwiper ${s.customArrow} market-swiper`}
                            >

                                {levels && levels.map((e, i) => (
                                    <SwiperSlide key={i} style={{ borderRadius: 0 }}>
                                        <Button component={NavLink}
                                            sx={{
                                                mr: 1,
                                                width: "100%"
                                            }}
                                            to={`?p=${programParam}&l=${e.summ}&m=0`} variant={Number(levelParam) === Number(e.summ) ? "contained" : `outlined`}>
                                            <Box display='flex' flexDirection='column' alignItems='center'>
                                                <Typography textAlign='center' component='p' sx={{ fontWeight: 500 }}>
                                                    {e.title2}
                                                </Typography>
                                                <Typography textAlign='center' component='p'>
                                                    {Number(e.summ) > 999999 ?
                                                        Intl.NumberFormat('ru', {
                                                            minimumFractionDigits: 0,
                                                            style: "currency", currency: "RUB",
                                                            notation: "compact",
                                                            maximumFractionDigits: 1
                                                        }).format(e.summ)
                                                        :
                                                        new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(e.summ)
                                                    }
                                                    {/* {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(e.summ)} */}

                                                </Typography>
                                            </Box>
                                        </Button>
                                    </SwiperSlide>
                                ))}

                                {/* <IconButton
                                    onClick={() => swiperRef.current?.slidePrev()}
                                    sx={{
                                        display: { xs: "none" },
                                        background: '#8487c575',
                                        width: { xs: "24px", lg: '22px' },
                                        height: { xs: "24px", lg: '22px' },
                                        mr: 2,
                                        position: "absolute",
                                        top: '10%',
                                        left: 10,
                                        zIndex: "555",
                                        "&:hover": {
                                            background: '#8c8c8c96',
                                        }
                                    }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="3"
                                        height="10"
                                        fill="none"
                                        viewBox="0 0 7 14"
                                    >
                                        <path
                                            stroke="#151ee9"
                                            strokeLinecap="round"
                                            strokeWidth="2"
                                            d="M6 13.002l-3.879-3.88a3 3 0 010-4.242L6 1.001"
                                        ></path>
                                    </svg>
                                </IconButton>

                                <IconButton
                                    onClick={() => swiperRef.current?.slideNext()}
                                    sx={{
                                        display: { xs: "none" },
                                        background: '#8487c575',
                                        width: { xs: "24px", lg: '22px' },
                                        height: { xs: "24px", lg: '22px' },
                                        position: "absolute",
                                        top: '10%',
                                        right: 0,
                                        zIndex: "555",
                                        "&:hover": {
                                            background: '#8c8c8c96',
                                        }
                                    }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7"
                                        height="15"
                                        fill="none"
                                        viewBox="0 0 7 15"
                                    >
                                        <path
                                            stroke="#151ee9"
                                            strokeLinecap="round"
                                            strokeWidth="2"
                                            d="M1 1.775l3.879 3.879a3 3 0 010 4.242L1 13.775"
                                        ></path>
                                    </svg>
                                </IconButton> */}
                            </Swiper >
                        }
                    </Box>
                </Container>
            </Box>
        </>
    )
}
